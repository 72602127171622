import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./TournamentPage.module.css";
import logo from "../assets/fanup_logo_white.png";
import Select from "react-select";
import CustomNavbar from "./CustomNavbar";
import LoadingSpinner from "./LoadingSpinner";
import fetchWrapper from "../utils/fetchWrapper";

const TournamentPage = () => {
  const navigate = useNavigate();
  const { tournamentName } = useParams();
  const [tournament, setTournament] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  function goToLiveBudgetSheet() {
    navigate(`/livebudgetsheet/${tournamentName}`);
  }

  function goToTeamList() {
    navigate(`/teamlist/${tournamentName}`);
  }

  useEffect(() => {
    const fetchTournament = async () => {
      try {
        const response = await fetchWrapper(
          `${BACKEND_URL}/tournament/${tournamentName}`,
          {
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTournament(data);
        } else {
          console.error("Failed to fetch tournament");
        }
      } catch (error) {
        console.error("Error fetching tournament:", error);
      }
    };

    const fetchTournaments = async () => {
      try {
        const response = await fetchWrapper(`${BACKEND_URL}/tournament/all`, {
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setTournaments(data);
        } else {
          console.error("Failed to fetch tournaments");
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    if (tournamentName) {
      fetchTournament();
      fetchTournaments();
    }
  }, [tournamentName]);

  return (
    <div className={styles.tournamentPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>
            {tournament ? tournament.tournamentName : "TOURNAMENT PAGE"}
          </span>
        </div>
      </nav>
      <CustomNavbar /> {/* Use the navbar component here */}
      {!tournament && <LoadingSpinner />}
      {tournament && (
        <div className={styles.dashboardGrid}>
          <div className={styles.gridItem}>
            <h3>RULES</h3>
            <a
              href={tournament.rules}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>SCHEDULE</h3>
            <div className={styles.scheduleContent}>
              {/* <p>{tournament.startDate} - {tournament.endDate}</p> */}
            </div>
            <a
              href={tournament.schedule}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW MORE</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>NEWSFEED</h3>
            <div className={styles.newsfeedContent}>
              {/* Display News or Twitter Feed */}
            </div>
            <a
              href={tournament.newsfeedLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW ALL</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>FANTASY</h3>
            <div className={styles.fantasyImage}>
              {/* Display Fantasy Image or Info */}
            </div>
            <a
              href={tournament.fantasyLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW MORE</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>PLAYERS</h3>
            <a
              href={tournament.playersLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW ALL</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>TEAMS</h3>
            <a
              href={tournament.teamsLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button onClick={goToTeamList}>VIEW ALL</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>AUCTION</h3>
            <a target='_blank' rel='noopener noreferrer'>
              <button onClick={goToLiveBudgetSheet}>VIEW</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>RESULTS</h3>
            <a
              href={tournament.results}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW ALL</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>TIMELINE</h3>
            <a
              href={tournament.timeline}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW ALL</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>SPONSORS</h3>
            <div className={styles.sponsorsContent}>
              {/* Display Sponsor Logos */}
            </div>
            <a
              href={tournament.sponsorLogo}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW ALL</button>
            </a>
          </div>
          <div className={styles.gridItem}>
            <h3>GALLERY</h3>
            <div className={styles.galleryContent}>{/* Display Gallery */}</div>
            <a
              href={tournament.gallery}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button>VIEW ALL</button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default TournamentPage;
