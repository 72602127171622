import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ChooseSportsPage from "./ChooseSportsPage";
import PlayerSelectionPage from "./PlayerSelectionPage";
import FormSubmissionPage from "./FormSubmissionPage";
import RegistrationDetailsPage from "./RegistrationDetailsPage";
import fetchWrapper from "../utils/fetchWrapper";

function InterUniversitySignupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const tournamentName = location.state?.tournamentName || "";

  // Initialize state with existing registration details if provided
  const [step, setStep] = useState(location.state?.selectedSports ? 1 : 1);
  const [selectedSports, setSelectedSports] = useState(
    location.state?.selectedSports || []
  );
  const [selectedPlayers, setSelectedPlayers] = useState(
    location.state?.selectedPlayers || []
  );
  const [isRegistered, setIsRegistered] = useState(false); // Track if the user is already registered
  const [registrationDetails, setRegistrationDetails] = useState(null); // Store existing registration details

  const handleNextStep = () => setStep((prevStep) => prevStep + 1);
  const handlePreviousStep = () => setStep((prevStep) => prevStep - 1);
  const handleModifySelection = () => {
    setStep(1);
    setSelectedSports(location.state?.selectedSports || []);
    setSelectedPlayers(location.state?.selectedPlayers || []);
    setIsRegistered(false); // Ensure the flow resets
    setRegistrationDetails(null); // Clear existing registration details
  };

  useEffect(() => {
    if (location.state?.selectedSports || location.state?.selectedPlayers) {
      setStep(1);
      setSelectedSports(location.state.selectedSports || []);
      setSelectedPlayers(location.state.selectedPlayers || []);
      setIsRegistered(false);
    }
  }, [location.state]);

  const collegeName = sessionStorage.getItem("collegeName");
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch registration details upon mounting
    const fetchRegistrationDetails = async () => {
      try {
        const response = await fetchWrapper(
          `${REACT_APP_BACKEND_URL}/get-registration-details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              collegeName,
              tournamentName,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setRegistrationDetails(data.registration);
          setIsRegistered(true); // Set to true if a registration exists
        } else {
          setIsRegistered(false); // No registration found
        }
      } catch (error) {
        console.error("Error fetching registration details:", error);
        setIsRegistered(false);
      }
    };

    fetchRegistrationDetails();
  }, [collegeName, tournamentName, REACT_APP_BACKEND_URL]);
  if (isRegistered && step === 1) {
    // Render the RegistrationDetailsPage if the user is already registered
    return (
      <RegistrationDetailsPage
        registrationDetails={registrationDetails}
        tournamentName={tournamentName}
        onModifySelection={handleModifySelection} // Pass the function to handle modification
      />
    );
  }

  return (
    <div>
      {step === 1 && (
        <ChooseSportsPage
          selectedSports={selectedSports}
          setSelectedSports={setSelectedSports}
          onNext={handleNextStep}
          collegeName={collegeName}
          navigate={navigate}
          tournamentName={tournamentName}
        />
      )}
      {step === 2 && (
        <PlayerSelectionPage
          selectedSports={selectedSports}
          selectedPlayers={selectedPlayers}
          setSelectedPlayers={setSelectedPlayers}
          onNext={handleNextStep}
          onPrevious={handlePreviousStep}
          collegeName={collegeName}
          navigate={navigate}
          tournamentName={tournamentName}
        />
      )}
      {step === 3 && (
        <FormSubmissionPage
          selectedSports={selectedSports}
          selectedPlayers={selectedPlayers}
          onPrevious={handlePreviousStep}
          collegeName={collegeName}
          navigate={navigate}
          tournamentName={tournamentName}
        />
      )}
    </div>
  );
}

export default InterUniversitySignupPage;
