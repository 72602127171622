import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AuctionPage.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from "./CustomNavbar";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import fetchWrapper from "./../utils/fetchWrapper";

const MySwal = withReactContent(Swal);

const AuctionPage = () => {
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [players, setPlayers] = useState([]);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [tournamentName, setTournamentName] = useState('');
  
  useEffect(() => {
    const storedTournamentName = sessionStorage.getItem('tournamentName');

    if (!storedTournamentName) {
      console.error('No tournament name found');
      MySwal.fire({
        title: 'Authentication Required',
        text: 'You are not authorised to be here!',
        icon: 'warning',
        confirmButtonText: 'OK',
        color:"#fff",
        background:"#333",
        theme:"dark",
        backdrop: true,
      }).then(() => {
        navigate('/');
      });
      return;
    }

    setTournamentName(storedTournamentName);

    const fetchPlayers = async () => {
      try {
        const playersResponse = await fetchWrapper(`${BACKEND_URL}/fetch-players-for-tournament/${storedTournamentName}`, {
          credentials: 'include',
        });

        if (playersResponse.ok) {
          const playersData = await playersResponse.json();
          setPlayers(playersData);
        } else {
          throw new Error('Failed to fetch players');
        }
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchPlayers();
  }, [navigate]);

  const goToAuctionPage = () => {
    navigate('/dashboard', { state: { tournamentName: tournamentName } });
  };
  const goToBudgetSheet = () => {
    navigate('/livebudgetsheet', { state: { tournamentName: tournamentName } });
  };

  const goToNextPlayer = () => {
    setCurrentPlayerIndex((prevIndex) => (prevIndex + 1) % players.length);
    console.log(currentPlayerIndex)
  };

  const goToPreviousPlayer = () => {
    setCurrentPlayerIndex((prevIndex) => (prevIndex - 1 + players.length) % players.length);
  };

  return (
    <div className={styles.auctionPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="FanUp Logo" className={styles.logo} />
          <span className={styles.registrationText}>AUCTION PAGE</span>
        </div>
      </nav>
      <CustomNavbar />

          <div className={styles.auctionContainer}>
            {players.length > 0 && (
              <div className={styles.playerDetails}>
                <div className={styles.nameButtons}>
                <div className={styles.playerNameContainer}>
              <span className={styles.playerName}>
                {players[currentPlayerIndex].fullName.split(' ').map((part, index) => (
                  <span
                    key={index}
                    className={index % 2 === 0 ? styles.regularNamePart:styles.boldNamePart}
                  >
                    {part}{' '}
                  </span>
                ))}
              </span>
            </div>


              <div className={styles.buttons}>
                <button onClick={goToPreviousPlayer} className={styles.prevButton}>
                  Previous 
                </button>
                <button onClick={goToNextPlayer} className={styles.nextButton}>
                  Next 
                </button>
              </div>
            </div>

            <div className={styles.playerInfo}>
              <p className={styles.title}>Student ID</p>
              <p className={styles.titleanswer}>{players[currentPlayerIndex].studentIdNumber}</p>

              <p className={styles.title}>Email ID</p>
              <p className={styles.titleanswer}>{players[currentPlayerIndex].email}</p>

              <p className={styles.title}>Phone Number</p>
              <p className={styles.titleanswer}>{players[currentPlayerIndex].phoneNumber}</p>

              <p className={styles.title}>Position</p>
              <p className={styles.titleanswer}>{players[currentPlayerIndex].position}</p>

              <p className={styles.title}>Player Description</p>
              <p className={styles.titleanswer}>{players[currentPlayerIndex].playerDescription}</p>
            </div>
          </div>
        )}
            <div className={styles.auctionControls}>

            <button onClick={goToAuctionPage} className={styles.saveButton}>Back to Dashboard</button>
        <button onClick={goToBudgetSheet} className={styles.saveButton}>Live Budget Sheet</button>
      </div>
      </div>
  
    </div>
  );
};

export default AuctionPage;
