import { getAuthCookie } from "./auth";

const fetchWrapper = async (url, options = {}) => {
    try {
        const authToken = getAuthCookie();
        console.log(`authToken ${authToken}`)
        // Default headers
        const defaultHeaders = {
            // 'Content-Type': 'application/json',
        };

        if(authToken!=null){
            defaultHeaders['Authorization'] = `Bearer ${authToken}`;
        }

        // Merge the passed options with default headers
        const config = {
            ...options,
            headers: {
                ...defaultHeaders,
                ...options.headers,
            },
            credentials: "include",
        };

        console.log(url, config);

        // Perform the fetch request
        return fetch(url, config);
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

export default fetchWrapper;
