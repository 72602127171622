import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import styles from './CollegeDashboardPage.module.css';
import CustomNavbar from "./CustomNavbar";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LoadingSpinner from './LoadingSpinner';
import fetchWrapper from '../utils/fetchWrapper';

const MySwal = withReactContent(Swal);

function CollegeDashboardPage() {
  const collegeName = sessionStorage.getItem('collegeName');
  const [selectedSports, setSelectedSports] = useState([]);
  const [uploadStatusMap, setUploadStatusMap] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const sportsGradients = [
    { name: 'Basketball', gradient: 'linear-gradient(to right, #F0B672, #E58A1F)' },
    { name: 'Football', gradient: 'linear-gradient(to right, #98E085, #4DA736)' },
    { name: 'Tennis', gradient: 'linear-gradient(to right, #689DCF, #1F63A2)' },
    { name: 'Badminton', gradient: 'linear-gradient(to right, #FFD700, #FF8C00)' },
    { name: 'Cricket', gradient: 'linear-gradient(to right, #87CEEB, #4682B4)' },
    { name: 'Pool', gradient: 'linear-gradient(to right, #00BFFF, #1E90FF)' },
    { name: 'Squash', gradient: 'linear-gradient(to right, #FF6347, #FF4500)' },
    { name: 'Table-Tennis', gradient: 'linear-gradient(to right, #3CB371, #2E8B57)' },
    { name: 'Chess', gradient: 'linear-gradient(to right, #FFDAB9, #E6B800)' },
    { name: 'Volleyball', gradient: 'linear-gradient(to right, #F08080, #CD5C5C)' },
    { name: 'Powerlifting', gradient: 'linear-gradient(to right, #A52A2A, #800000)' },
    { name: 'Swimming', gradient: 'linear-gradient(to right, #B0C4DE, #5F9EA0)' },
  ];

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setIsLoading(true);
        const res = await fetchWrapper(`${BACKEND_URL}/dashboard-data?collegeName=${collegeName}`, {
          method: 'GET',
          credentials: 'include', // Include cookies for authentication
        });
  
        if (res.ok) {
          const data = await res.json();
          setSelectedSports(data.selectedSports);
          setUploadStatusMap(data.uploadStatusMap || {});
          setIsLoading(false);
        } else {
          navigate('/'); // Redirect if not authenticated
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        navigate('/');
      }
    };
  
    fetchDashboardData();
  }, [collegeName, BACKEND_URL, navigate]);
  
  

  // Fetch sports and upload status
  useEffect(() => {
    const fetchSports = async () => {
      try {
        setIsLoading(true);
        const response = await fetchWrapper(`${BACKEND_URL}/get-sports?collegeName=${collegeName}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setSelectedSports(data.selectedSports);
          fetchUploadStatus(data.selectedSports);
          setIsLoading(false);
        } else {
          console.error('Failed to fetch sports:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const fetchUploadStatus = async (sports) => {
      const statusMap = {};
      await Promise.all(
        sports.map(async (sport) => {
          try {
            const response = await fetchWrapper(
              `${BACKEND_URL}/register-sport/status/${sport.name}/${collegeName}`,
              {
                method: 'GET',
                credentials: 'include',
              }
            );
            const result = await response.json();
            statusMap[sport.name] = result.uploaded;
          } catch (error) {
            console.error(`Error fetching upload status for ${sport.name}:`, error);
          }
        })
      );
      setUploadStatusMap(statusMap);
    };

    fetchSports();
  }, [collegeName, BACKEND_URL]);

  const handleModifySports = () => {
    navigate(`/sportselection`, { state: { modify: true, collegeName } });
  };

  return (
    <div className={styles.dashboardContainer}>
          {isLoading && <LoadingSpinner /> }
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>College Dashboard</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.ribbon}>
        <p className={styles.ribbonText}>{collegeName} SPORTS DASHBOARD</p>
      </div>
      <div className={styles.sportsListContainer}>
        <div className={styles.sportsGrid}>
          {selectedSports.length > 0 &&
            selectedSports.map((sport) => {
              const gradient = sportsGradients.find((g) => g.name === sport.name)?.gradient || 'linear-gradient(to right, #000000, #808080)';
              const isUploaded = uploadStatusMap[sport.name] || false;
              return (
                <div
                  key={sport.name}
                  className={styles.sportCard}
                  style={{
                    background: isUploaded ? gradient : 'transparent',
                  }}
                  onClick={() =>
                    navigate(`/updatesportdetails/${collegeName}/${sport.name.toLowerCase()}`, {
                      state: { sport, collegeName },
                    })
                  }
                >
                  <h3 className={styles.sportName}>{sport.name.toUpperCase()}</h3>
                  <p className={styles.fillDetails}>{isUploaded ? 'Uploaded' : '+ Fill Details'}</p>
                </div>
              );
            })}
        </div>
        <button className={styles.submitButton} onClick={handleModifySports}>
          Change Sports Selection
        </button>
      </div>
    </div>
  );
}

export default CollegeDashboardPage;
