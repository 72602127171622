// src/utils/auth.js

import { deleteCookie, getCookie, setCookie } from "./helpers";

export const isAuthenticated = () => {
  if (getAuthCookie() != null) {
    return true;
  }
  return false;
};

export const getAuthCookie = () => {
  return localStorage.getItem('authToken');
  // return getCookie('authToken');
}

export const setAuthCookie = (token) => {
  return localStorage.setItem('authToken', token);
  // return setCookie('authToken', token);
}

export const removeAuthCookie = () => {
  return localStorage.removeItem('authToken');
  // return deleteCookie('authToken');
}