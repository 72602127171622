import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/fanup_logo_white.png";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./SignupPage.module.css";
import CustomNavbar from "./CustomNavbar";
import fetchWrapper from "../utils/fetchWrapper";

function SignupPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    collegeName: "",
    verificationCode: "",
    coordinatorName: "",
    coordinatorNumber: "",
    coordinatorEmail: sessionStorage.getItem("userEmail") || "",
  });
  const [loading, setLoading] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetchWrapper(`${BACKEND_URL}/verify-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          collegeName: formData.collegeName,
          verificationCode: formData.verificationCode,
        }),
      });

      if (!response.ok) {
        const result = await response.json();
        console.error("Verification failed:", result);
        alert(result.error || "An error occurred. Please try again.");
        return;
      }

      const result = await response.json();
      console.log(result, "result");
      sessionStorage.setItem("collegeName", formData.collegeName);
      sessionStorage.setItem("userFullName", formData.coordinatorName);
      sessionStorage.setItem("userPhoneNumber", formData.coordinatorNumber);

      if (result.role === "admin") {
        // Check for Inter University tournament existence
        const tournamentResponse = await fetchWrapper(
          `${BACKEND_URL}/check-inter-university-tournament/${formData.collegeName}`,
          { credentials: "include" }
        );

        if (tournamentResponse.ok) {
          const tournamentResult = await tournamentResponse.json();
          console.log(tournamentResult, "tournamentResult");
          if (tournamentResult.exists) {
            navigate("/dashboard");
          } else {
            navigate("/create-tournament", {
              state: { collegeName: formData.collegeName },
            });
          }
        } else {
          alert("Failed to check tournament existence");
        }
      } else if (result.role === "user") {
        // Redirect to sport selection for user role
        navigate("/sportselection", {
          state: { collegeName: formData.collegeName },
        });
      } else {
        alert("Invalid role.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("A network error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.signupPage}>
      {loading && <LoadingSpinner />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='Logo' className={styles.logo} />
          <span className={styles.registrationText}>Registration Page</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.signupText}>Log In</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              type='text'
              name='collegeName'
              placeholder='College Name'
              value={formData.collegeName}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type='text'
              name='verificationCode'
              placeholder='Verification Code'
              value={formData.verificationCode}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type='text'
              name='coordinatorName'
              placeholder='Full Name'
              value={formData.coordinatorName}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type='text'
              name='coordinatorNumber'
              placeholder='Phone Number'
              value={formData.coordinatorNumber}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type='email'
              name='coordinatorEmail'
              placeholder='Email Address'
              value={formData.coordinatorEmail}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <button type='submit' className={styles.submitButton}>
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
