import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/fanup_logo_white.png";
import LoadingSpinner from "./LoadingSpinner";
import CustomNavbar from "./CustomNavbar";
import styles from "./ViewTournamentsPage.module.css";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import fetchWrapper from "../utils/fetchWrapper";

const MySwal = withReactContent(Swal);

function ViewTournamentsPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    fetchWrapper(`${BACKEND_URL}/tournament/all`)
      .then((response) => response.json())
      .then((data) => {
        setTournaments(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching tournaments:", error);
        setLoading(false);
      });
  }, [BACKEND_URL]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatTournamentDate = (startDate, endDate) => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const handleViewClick = (tournamentName) => {
    navigate(`/tournament/${tournamentName}`);
  };

  const handleRegisterClick = async (tournamentName) => {
    try {
      setLoading(true);

      // Step 1: Fetch the tournament type
      const response = await fetchWrapper(
        `${BACKEND_URL}/tournament-type/${tournamentName}`
      );

      console.log("Response:", response);
      const data = await response.json();

      if (!response.ok) {
        Swal.fire({
          title: "Error",
          text: data.error || "Failed to fetch tournament type.",
          icon: "error",
          confirmButtonText: "OK",
          color: "#fff",
          background: "#333",
        });
        return;
      }

      const { tournamentType } = data;

      if (tournamentType === "Inter-University") {
        // Step 2: Check if the user is a collegeCoordinator
        const roleCheckResponse = await fetchWrapper(
          `${BACKEND_URL}/player-registration`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        const roleData = await roleCheckResponse.json();

        console.log(roleData, "role data");

        if (!roleCheckResponse.ok || roleData.role !== "collegeCoordinator") {
          Swal.fire({
            title: "Permission Denied",
            text: "You must be a college coordinator to register for inter-university tournaments.",
            icon: "warning",
            confirmButtonText: "OK",
            color: "#fff",
            background: "#333",
          });
          return;
        }

        // Step 3: Redirect to InterUniversitySignupPage
        navigate("/interuniversitysignup", { state: { tournamentName } });
      } else if (tournamentType === "Intra-University") {
        navigate("/usersignup", { state: { tournamentName } });
      } else {
        Swal.fire({
          title: "Invalid Tournament Type",
          text: "The tournament type is invalid. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
          color: "#fff",
          background: "#333",
        });
      }
    } catch (error) {
      console.error("Error handling register click:", error);
      Swal.fire({
        title: "Error",
        text: "A network error occurred. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        color: "#fff",
        background: "#333",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.loginPage}>
      {loading && <LoadingSpinner />} {/* Render the spinner if loading */}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='Logo' className={styles.logo} />
          <span className={styles.registrationText}>Tournament Page</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.tournamentListContainer}>
        {tournaments
          .filter((tournament) => tournament.verificationCode) // Filter out tournaments without verificationCode
          .map((tournament, index) => (
            <div key={index} className={styles.tournamentRow}>
              <div className={styles.tournamentLogo}>
                <img src={tournament.logo} alt={`${tournament.name} Logo`} />
              </div>
              <div className={styles.tournamentDetails}>
                <h3 className={styles.nameOfTournament}>
                  {tournament.tournamentName}
                </h3>
                <p className={styles.sponsorText}>
                  Sponsored by{" "}
                  {tournament.sponsorList
                    ? tournament.sponsorList
                    : "<<TOURNAMENT SPONSOR>>"}
                </p>
                <p className={styles.locationText}>
                  <FontAwesomeIcon
                    color='red'
                    className={styles.locationPin}
                    icon={faMapMarkerAlt}
                  />
                  {tournament.location}
                </p>
              </div>
              <div className={styles.tournamentDate}>
                <p>
                  {formatTournamentDate(
                    tournament.startDate,
                    tournament.endDate
                  )}
                </p>
              </div>
              <div className={styles.tournamentActions}>
                <button
                  className={styles.viewButton}
                  onClick={() => handleViewClick(tournament.tournamentName)}
                >
                  View
                </button>
                <button
                  className={styles.registerButton}
                  onClick={() => handleRegisterClick(tournament.tournamentName)}
                >
                  Register
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ViewTournamentsPage;
