import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/fanup_logo_white.png";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./LoginPage.module.css";
import CustomNavbar from "./CustomNavbar";
import { setAuthCookie } from "../utils/auth";
import fetchWrapper from "../utils/fetchWrapper";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleGoogleCallbackResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("GoogleButton"),
        { theme: "outline", size: "large", shape: "circle", type: "standard" }
      );
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);
  }, []);

  const handleGoogleCallbackResponse = async (response) => {
    setLoading(true);
    try {
      const res = await fetchWrapper(`${BACKEND_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: response.credential }),
        credentials: "include", // Include cookies for authentication
      });

      if (res.ok) {
        const data = await res.json();
        setAuthCookie(data.token);
        handleUserRedirection(data.email); // Pass email to redirection handler
      } else {
        console.error("Google login failed");
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRegularLogin = async () => {
    setLoading(true);
    try {
      const res = await fetchWrapper(`${BACKEND_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include",
      });

      if (res.ok) {
        const data = await res.json();
        setAuthCookie(data.token);
        handleUserRedirection(email); // Pass email to redirection handler
      } else {
        console.error("Regular login failed");
      }
    } catch (error) {
      console.error("Error during regular login:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserRedirection = async (email) => {
    try {
      // Set userEmail in sessionStorage
      sessionStorage.setItem("userEmail", email);

      if (email === "zahaan@shapoorjee.com") {
        navigate("/admin");
      }

      const res = await fetchWrapper(`${BACKEND_URL}/player-registration`, {
        method: "GET",
        credentials: "include",
      });

      if (res.ok) {
        const data = await res.json();

        switch (data.role) {
          case "coordinator":
            sessionStorage.setItem("tournamentName", data.tournamentName);
            sessionStorage.setItem("collegeName", data.collegeName);
            navigate(data.dashboardRedirect); // Redirect to coordinator dashboard
            break;
          case "collegeCoordinator":
            sessionStorage.setItem("collegeName", data.collegeName);
            navigate(data.dashboardRedirect); // Redirect to college dashboard
            break;
          case "player":
            sessionStorage.setItem("tournamentName", data.tournamentName);
            navigate(`/tournament/${data.tournamentName}`); // Redirect to tournament page
            break;
          default:
            navigate("/signup"); // Redirect to signup if no role is found
        }
      } else {
        navigate("/signup");
      }
    } catch (error) {
      console.error("Error fetching user registration:", error);
      navigate("/signup");
    }
  };

  return (
    <div className={styles.loginPage}>
      {loading && <LoadingSpinner />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='Logo' className={styles.logo} />
          <span className={styles.registrationText}>Registration Page</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.signupText}>Login</h2>
          <input
            type='email'
            placeholder='Email'
            className={styles.inputField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type='password'
            placeholder='Password'
            className={styles.inputField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className={styles.loginButton} onClick={handleRegularLogin}>
            Login
          </button>
          <a href='#' className={styles.forgotPasswordLink}>
            Forgot Password?
          </a>
          <div className={styles.orSeparator}>
            <hr className={styles.line} />
            <span>OR</span>
            <hr className={styles.line} />
          </div>
          <div id='GoogleButton'></div>
          <div className={styles.signupLink}>
            Don't Have an Account?{" "}
            <a href='/signup' className={styles.signUpLinkText}>
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
