import React, { useState } from 'react';
import styles from './FormSubmissionPage.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from './CustomNavbar';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import fetchWrapper from "./../utils/fetchWrapper";

const MySwal = withReactContent(Swal);

function FormSubmissionPage({ selectedSports, selectedPlayers, collegeName, tournamentName, onPrevious, navigate }) {
  const sports = [
    { name: 'Basketball', gradient: 'linear-gradient(to right, #F0B672, #E58A1F)' },
    { name: 'Football', gradient: 'linear-gradient(to right, #98E085, #4DA736)' },
    { name: 'Tennis', gradient: 'linear-gradient(to right, #689DCF, #1F63A2)' },
    { name: 'Badminton', gradient: 'linear-gradient(to right, #FFD700, #FF8C00)' },
    { name: 'Cricket', gradient: 'linear-gradient(to right, #87CEEB, #4682B4)' },
    { name: 'Pool', gradient: 'linear-gradient(to right, #00BFFF, #1E90FF)' },
    { name: 'Squash', gradient: 'linear-gradient(to right, #FF6347, #FF4500)' },
    { name: 'Table-Tennis', gradient: 'linear-gradient(to right, #3CB371, #2E8B57)' },
    { name: 'Chess', gradient: 'linear-gradient(to right, #FFDAB9, #E6B800)' },
    { name: 'Volleyball', gradient: 'linear-gradient(to right, #F08080, #CD5C5C)' },
    { name: 'Powerlifting', gradient: 'linear-gradient(to right, #A52A2A, #800000)' },
    { name: 'Swimming', gradient: 'linear-gradient(to right, #B0C4DE, #5F9EA0)' },
  ];

  const [verificationCode, setVerificationCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const REACT_APP_BACKEND_URL  = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const response = await fetchWrapper(`${REACT_APP_BACKEND_URL}/register-interuniversity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          verificationCode,
          selectedSports,
          selectedPlayers, // Now includes `_id` and `fullName`
          collegeName,
          tournamentName,
        }),
      });
  
      if (response.ok) {
        setIsLoading(false);
        await MySwal.fire({
          title: 'Success',
          text: 'Successfully registered for the tournament!',
          icon: 'success',
          color: '#fff',
          background: '#333',
        });
        navigate('/collegedashboard');
      } else {
        const result = await response.json();
        setError(result.error || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className={styles.formSubmissionPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.pageTitle}>{tournamentName} Registration</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.headerText}>Confirm Your Registration</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.summarySection}>
              <h3 className={styles.summaryTitle}>Selected Sports and Players:</h3>
              <ul className={styles.summaryList}>
                {selectedSports.map((sport, index) => (
                  <li key={index} className={styles.summaryItem}>
                    <div className={styles.sportTitleContainer}>
                      <span
                        className={styles.sportTitle}
                        style={{
                          background: `linear-gradient(to right, #FFD700, #FF8C00)`, // Adjust based on your styles
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        {sport} Players
                      </span>
                    </div>
                    <ul className={styles.playerList}>
                    {selectedPlayers[sport]?.map((player, idx) => (
                    <li key={player._id} className={styles.playerItem}>
                      {player.fullName}
                    </li>
                      ))}

                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="verificationCode" className={styles.label}>
                Verification Code
              </label>
              <input
                type="text"
                id="verificationCode"
                className={styles.inputField}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </div>
            {error && <p className={styles.error}>{error}</p>}
            <div className={styles.navButtons}>
              <button
                type="button"
                className={styles.previousButton}
                onClick={onPrevious}
                disabled={isSubmitting}
              >
                Back
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={!verificationCode || isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FormSubmissionPage;
